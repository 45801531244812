import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_Trio_BackdropAndTitle.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';

export default {
  name: 'Rock-Tac-Toe',
  primaryAccentColor: 'Blue.Magic',
  secondaryAccentColor: 'Blue.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['1+ Players', 'Competitive'],
  description: 'Play Tic-Tac-Toe with Rock Paper Scissors tiles.',
  elevatorPitch: "It's like Rock Paper Scissors and Tic-Tac-Toe had a baby!",
  minPlayers: 1,
  howToSlides: [
    {
      img: Slide1,
      altImg: "It's Rock Paper Scissors on a Tic-Tac-Toe grid",
    },
    {
      img: Slide2,
      altImg: 'Earn a STAR by placing 3 tiles in a row',
    },
    {
      img: Slide3,
      altImg: 'Battle opponents for grid spaces and break ties',
    },
    {
      img: Slide4,
      altImg: '6+ stars (or highest at the end of 25 turns) = YOU WIN!',
    },
  ],
  gameModes: [],
  contentPacks: [],
  quickBits: [
    {
      emoji: '⏱️',
      text: '5 mins',
    },
    {
      emoji: '👥',
      text: '2+ players',
    },
    {
      emoji: '♟️',
      text: 'Tactical',
    },
  ],
  isNew: true,
} satisfies GameMetaData;
