import { ContentPack } from '@common/types/content-pack';

export const guavaContentPacks = [
  {
    name: 'Regular',
    emoji: '🍦',
  },
  {
    name: 'Advanced',
    emoji: '📚',
  },
  {
    name: 'Cringe',
    emoji: '🤢',
  },
] as const satisfies ContentPack[];

export type GuavaContentPack = (typeof guavaContentPacks)[number];
export type GuavaContentPackName = GuavaContentPack['name'];
