import { GameMode } from '@common/types/game-mode';

export const guavaGameModes = [
  {
    name: 'Team',
    emoji: '🤝',
    description: 'Work together to solve the puzzle',
  },
  {
    name: 'Competition',
    emoji: '🏆',
    description: 'Compete for the highest score',
    minPlayers: 3,
  },
] as const satisfies GameMode[];

export type GuavaGameMode = (typeof guavaGameModes)[number];
export type GuavaGameModeName = GuavaGameMode['name'];
