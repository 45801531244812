import WebSocketCloseCode, {
  PermanentlyDisconnectedCloseCode,
  permanentlyDisconnectedCloseCodes,
} from '@common/WebSocketCloseCode';
import { RemoteOperationError } from '@common/errors';
import { RoomMessage } from '@common/games/Room/messages';
import { RoomData } from '@common/games/Room/types';
import { isNoisyClientToServerMessage } from '@common/noisy-messages';
import {
  ClientToServerMessage,
  ServerToClientMessage,
  WebSocketConnectionRequestParams,
} from '@common/types/messages';
import { IState } from '@common/types/state';
import { Immutable } from '@common/utils';
import { applyReducer } from 'fast-json-patch';
import { getDefaultStore } from 'jotai';
import _ from 'lodash';
import { configAtom } from '../config';
import { deploymentVersion, platform, surface } from '../environment';
import {
  anonymousUserStyleAtom,
  discordAccessTokenAtom,
  discordSdkAtom,
  inappropriateContentAtom,
  isLoadingAnimationVisibleAtom,
  isUserAuthenticatedAtom,
  mutedPlayersAtom,
  playerIdAtom,
  stateAtom,
} from '../store/store';
import { syncPortalToAtom } from '@/PublicRooms';
import { playSoundEffect } from '@/audio/soundEffects/soundEffect';
import { SoundEffectsName } from '@/audio/soundEffects/soundEffectsUrls';
import { getRoomServerApiRoot } from '@/utils';

type ReconnectionSuccess = {
  isConnected: true;
};

type ReconnectionFailure = {
  isConnected: false;
  numConsecutiveAttempts: number;
};

type ReconnectionState = ReconnectionSuccess | ReconnectionFailure;

type PendingBinaryMessage = {
  blob: Blob;
  kind: 'binary';
};

type PendingJsonMessage = {
  message: ClientToServerMessage;
  kind: 'object';
};

type PendingMessage = PendingBinaryMessage | PendingJsonMessage;

declare global {
  interface Window {
    MagicCircle_RoomConnection: RoomConnection;
    onAppContentLoaded: () => void;
  }
}
const emoteSFX: SoundEffectsName[] = [
  'Emote_SFX_Happy_02',
  'Emote_SFX_Love_02',
  'Emote_SFX_Mad_02',
  'Emote_SFX_Sad_02',
  'Emote_SFX_Heart_02',
];

const getEmoteSFX = (type: number): SoundEffectsName => {
  const sfx = emoteSFX[type];
  return sfx || 'Button_Main_01';
};

export default class RoomConnection {
  private currentWebSocket: WebSocket | null = null;
  private heartbeatInterval: number | undefined;
  private lastHeartbeatFromServer = Date.now();
  private roomState: Immutable<IState<RoomData>> | undefined;

  private readonly pendingMessages: PendingMessage[] = [];

  // A connection is "Supesceded" if the user has opened a new connection to the
  // same room. This can happen if the user opens the same room in multiple
  // tabs.
  // This is a weird and unsupported state, because we don't want to have
  // multiple connections for the same playerId in the same room.
  public onPermanentlyDisconnected?: (
    reason: PermanentlyDisconnectedCloseCode | null
  ) => void;

  private numConsecutiveReconnectionAttempts = 0;
  public onReconnectionAttempt?: (state: ReconnectionState) => void;

  private readonly rejoin: () => void;
  private get isDocumentHidden() {
    return document.visibilityState === 'hidden';
  }

  private constructor() {
    if (window.MagicCircle_RoomConnection) {
      throw new Error(
        'RoomConnection is a singleton. Use RoomConnection.getInstance() instead.'
      );
    }

    this.rejoin = _.throttle(
      () => {
        this.numConsecutiveReconnectionAttempts += 1;
        this.onReconnectionAttempt?.({
          isConnected: false,
          numConsecutiveAttempts: this.numConsecutiveReconnectionAttempts,
        });
        console.debug('rejoin()');
        this.join();
      },
      1000,
      { leading: false, trailing: true }
    );

    document.addEventListener('visibilitychange', () => {
      console.debug(
        `Document visibility changed to ${document.visibilityState}`
      );
    });
  }

  public static getInstance() {
    if (!window.MagicCircle_RoomConnection) {
      window.MagicCircle_RoomConnection = new RoomConnection();
    }
    return window.MagicCircle_RoomConnection;
  }

  private startHeartbeat() {
    this.lastHeartbeatFromServer = Date.now();
    console.debug('starting new heartbeat interval');
    this.heartbeatInterval = window.setInterval(() => {
      // if 10 seconds have passed since the last ping from the server
      const millisSinceLastHeartbeat =
        Date.now() - this.lastHeartbeatFromServer;
      if (!this.isDocumentHidden && millisSinceLastHeartbeat > 10_000) {
        console.info(
          `server heartbeat lost, ${millisSinceLastHeartbeat}ms elapsed`
        );
        this.rejoin();
      }
    }, 1000); // check every second
  }

  public disconnect(closeCode?: WebSocketCloseCode) {
    this.stopHeartbeat();

    if (this.currentWebSocket) {
      this.currentWebSocket.onopen = null;
      this.currentWebSocket.onmessage = null;
      this.currentWebSocket.onclose = null;

      let reason: string | undefined;
      switch (closeCode) {
        case WebSocketCloseCode.ConnectionSuperseded: {
          reason = 'connection superseded';
          break;
        }
        case WebSocketCloseCode.PlayerKicked: {
          reason = 'player kicked';
          break;
        }
        case WebSocketCloseCode.VersionMismatch: {
          reason = `version mismatch (${deploymentVersion})`;
          break;
        }
        case WebSocketCloseCode.HeartbeatExpired: {
          reason = `heartbeat expired`;
          break;
        }
        case WebSocketCloseCode.PlayerLeftVoluntarily: {
          reason = 'player left voluntarily';
          break;
        }
        default: {
          reason = undefined;
        }
      }

      // Only close the WebSocket if it's open, otherwise we'll get an error
      if (this.currentWebSocket.readyState === WebSocket.OPEN) {
        this.currentWebSocket.close(closeCode, reason);
      }
    }
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = undefined;
    }
  }

  public join() {
    // If an existing WebSocket connection is open, disconnect first
    this.disconnect(WebSocketCloseCode.ReconnectInitiated); // This close code is used ONLY when we are reconnecting immediately afterwards

    this.currentWebSocket = new WebSocket(getWebSocketUrl());
    this.currentWebSocket.onerror = (event) => {
      console.error('WebSocket error:', JSON.stringify(event));
    };

    // Attach event listeners
    this.currentWebSocket.onopen = this.onWebSocketOpen;
    this.currentWebSocket.onmessage = (event: MessageEvent<unknown>) => {
      this.onWebSocketMessage(event.data);
    };
    // Note: onclose can fire with or without onerror. So, we just ignore
    // onerror and handle all errors in onclose. Besides, onerror includes
    // no useful information about the error anyway.
    // More info: https://stackoverflow.com/a/40084550
    this.currentWebSocket.onclose = this.onWebSocketClose;
  }

  private readonly onWebSocketOpen = () => {
    console.log('WebSocket opened');
    this.stopHeartbeat();
    this.startHeartbeat();
    // Clear the reconnection attempt counter now that we're connected
    this.numConsecutiveReconnectionAttempts = 0;
    this.onReconnectionAttempt?.({
      isConnected: true,
    });
    this.pendingMessages.forEach((message) => {
      if (message.kind === 'binary') {
        this.sendBinaryMessage(message.blob);
      } else {
        this.sendMessage(message.message);
      }
    });
    this.pendingMessages.length = 0;
  };

  private readonly onWebSocketMessage = (data: unknown) => {
    // If we received a Ping message, we update the lastHeartbeatTime
    if (data === 'ping') {
      if (this.isDocumentHidden) {
        console.debug(
          'Received server ping, but document is hidden. Not sending pong.'
        );
      } else {
        this.currentWebSocket?.send('pong');
        console.debug('Received server ping, sending pong');
        this.lastHeartbeatFromServer = Date.now();
      }
    } else if (typeof data === 'string') {
      const message = JSON.parse(data) as ServerToClientMessage;
      console.debug(`received ${message.type} from server`);
      this.onServerToClientMessage(message);
    } else {
      console.error('Received unknown message', data);
    }
  };

  private readonly onWebSocketClose = (event: CloseEvent) => {
    console.log(
      `WebSocket closed (${event.code}) reason: ${event.reason || 'none'}`
    );
    const isPermanentlyDisconnected =
      permanentlyDisconnectedCloseCodes.includes(event.code);

    if (isPermanentlyDisconnected) {
      this.onPermanentlyDisconnected?.(event.code);
      this.stopHeartbeat();
      return;
    }

    if (this.isDocumentHidden) {
      console.log(
        'WebSocket closed, but document is hidden. Not attempting to reconnect.'
      );
    } else {
      this.rejoin();
    }
  };

  public sendRoomMessage(message: RoomMessage) {
    this.sendMessage({ scopePath: ['Room'], ...message });
  }

  public sendMessage(message: ClientToServerMessage) {
    if (
      !this.currentWebSocket ||
      this.currentWebSocket.readyState !== WebSocket.OPEN
    ) {
      this.pendingMessages.push({ message, kind: 'object' });
      console.warn(
        'No websocket connection. Message will be sent later when connection is established.',
        message
      );
      return;
    }
    if (!isNoisyClientToServerMessage(message)) {
      console.log('sending ClientToServer message', message);
    }

    this.currentWebSocket.send(JSON.stringify(message));
  }

  public sendBinaryMessage(blob: Blob) {
    // default chunk size is 512KiB
    // Note: CloudFlare Durable Objects imposes a max 1MiB limit on the size of
    // a single websocket message. So, we need to send the data in chunks.
    // See: https://developers.cloudflare.com/workers/platform/limits/#durable-objects
    const chunkSize: number = 1024 * 512;

    const reader = new FileReader();
    reader.onloadend = () => {
      if (
        !this.currentWebSocket ||
        this.currentWebSocket.readyState !== WebSocket.OPEN
      ) {
        this.pendingMessages.push({ blob, kind: 'binary' });
        console.warn(
          'No websocket connection. Message will be sent later when connection is established.'
        );
        return;
      }

      const arrayBuffer = reader.result as ArrayBuffer;

      // Prepare header: convert total size to a 32-bit integer ArrayBuffer
      const totalSizeBuffer = new Uint32Array([arrayBuffer.byteLength]).buffer;

      // Send header first (total size of the data)
      this.currentWebSocket.binaryType = 'arraybuffer';
      this.sendRoomMessage({ type: 'WillSendBinaryDoNotDisconnectMe' });
      console.log(`sending binary message of size ${blob.size}`);
      this.currentWebSocket.send(totalSizeBuffer);

      // Send arrayBuffer in chunks
      for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
        let end = i + chunkSize;

        // Make sure we don't exceed the buffer size
        if (end > arrayBuffer.byteLength) {
          end = arrayBuffer.byteLength;
        }

        const chunk = arrayBuffer.slice(i, end);
        this.currentWebSocket.send(chunk);
      }

      this.sendRoomMessage({ type: 'FinishedSendingBinary' });
    };
    reader.readAsArrayBuffer(blob);
  }

  private onServerToClientMessage = (message: ServerToClientMessage) => {
    const { get, set } = getDefaultStore();
    const messageType = message.type;
    switch (messageType) {
      case 'Welcome': {
        // We "clear" the permanently disconnected state when we receive a
        // Welcome message. This is useful for clearing the PermanentlyDisconnectedDialog.
        this.onPermanentlyDisconnected?.(null);

        this.roomState = message.fullState as Immutable<IState<RoomData>>;
        set(stateAtom, _.cloneDeep(this.roomState));

        // Inform the Rive loading spinner in index.html that the app has loaded
        window.onAppContentLoaded();

        break;
      }
      case 'PartialState': {
        if (!this.roomState) {
          console.error(
            'RoomConnection: Received PartialState message before Welcome message. Ignoring.'
          );
          break;
        }
        const prevState = _.cloneDeep(this.roomState);
        const nextState = message.patches.reduce(applyReducer, prevState);
        this.roomState = nextState;
        const deepCloneOfNextRoomState = _.cloneDeep(this.roomState);
        set(stateAtom, deepCloneOfNextRoomState);
        console.debug('Partial state patch applied, new state:', nextState);
        break;
      }
      case 'ServerErrorMessage': {
        const error = new RemoteOperationError(
          message.error,
          message.messageContext
        );
        console.error('RoomConnection: Received server error message', error);
        break;
      }
      case 'Config': {
        set(configAtom, message.config);
        break;
      }
      case 'InappropriateContentRejected': {
        set(inappropriateContentAtom, {
          content: message.content,
          reason: message.reason,
        });
        break;
      }
      case 'PortalsChanged': {
        if (surface !== 'discord') break;
        syncPortalToAtom()
          .then((didPortalChange) => {
            if (!didPortalChange) return;
            set(isLoadingAnimationVisibleAtom, true);
            console.log('portal changed, reconnecting');
            this.disconnect(WebSocketCloseCode.PlayerLeftVoluntarily);
            this.rejoin();
          })
          .catch((error) => {
            console.error('Error updating portal', error);
          });
        break;
      }
      case 'Emote': {
        const mutedPlayers = get(mutedPlayersAtom);
        if (!mutedPlayers.includes(message.playerId)) {
          playSoundEffect(getEmoteSFX(message.emoteType));
        }
        break;
      }
      default: {
        messageType satisfies never;
        console.error(`Unknown message type '${messageType}'`);
      }
    }
  };
}

function getWebSocketUrl(): string {
  const { get } = getDefaultStore();

  const url = new URL(
    // Convert http(s) to ws(s)
    getRoomServerApiRoot().replace(/^http/, 'ws') + '/connect'
  );

  const isLoggedIn = get(isUserAuthenticatedAtom);

  const params: WebSocketConnectionRequestParams = {
    surface,
    platform,
    // Never pass the playerId to the server when playing on Discord.
    // We use their Discord ID as their playerId instead.
    playerId: surface === 'web' ? get(playerIdAtom) : undefined,
    version: deploymentVersion,
    guildId: get(discordSdkAtom)?.guildId ?? undefined,
    discordActivityInstanceId: get(discordSdkAtom)?.instanceId ?? undefined,
    // On non-Discord surface, the token is passed via cookie
    discordAccessToken:
      surface === 'discord' ? get(discordAccessTokenAtom) : undefined,
    anonymousUserStyle: isLoggedIn ? undefined : get(anonymousUserStyleAtom),
  };

  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      continue;
    }
    url.searchParams.set(key, JSON.stringify(value));
  }

  return url.toString();
}
