import TrioMetaData from './Trio/TrioMetaData';
import { GameName } from '@common/types/games';
import AvocadoMetaData from './Avocado/AvocadoMetaData';
import ClementineMetaData from './Clementine/ClementineMetaData';
import DurianMetaData from './Durian/DurianMetaData';
import FarkleberryMetaData from './Farkleberry/FarkleberryMetaData';
import GuavaMetaData from './Guava/GuavaMetaData';
import KitchenSinkMetaData from './KitchenSink/KitchenSinkMetaData';
import McMindMetaData from './McMind/McMindMetaData';
import { GameMetaData } from './types';

type GameMetaDataMap = {
  [gameName in GameName]: GameMetaData;
};

const gameMetaDatas: GameMetaDataMap = {
  'Trio': TrioMetaData,
  Guava: GuavaMetaData,
  Farkleberry: FarkleberryMetaData,
  Durian: DurianMetaData,
  Clementine: ClementineMetaData,
  KitchenSink: KitchenSinkMetaData,
  Avocado: AvocadoMetaData,
  McMind: McMindMetaData,
};

export default gameMetaDatas;
