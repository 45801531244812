import { Center, Image } from '@chakra-ui/react';
import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_Durian_BackdropAndTitle.png';
import GameDetailsImage1 from './assets/durian-gamedetails-image.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide1Alt from './assets/howto/Slide1Alt.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';
import Slide5 from './assets/howto/Slide5.png';

export default {
  name: 'The Spy Game',
  primaryAccentColor: 'Yellow.Dark',
  secondaryAccentColor: 'Yellow.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Social Deduction', 'Words'],
  description: (
    <>
      Get a spread of words in one category. <br />
      Everyone except one person knows which word is the topic. <br />
      <Center my="24px">
        <Image
          src={GameDetailsImage1}
          alt="Example game screen"
          width="169px"
          height="236px"
        />
      </Center>
      Clue in that you know what the topic is without giving it away to the spy.
    </>
  ),
  elevatorPitch: (
    <>
      Figure out who amongst you doesn't know the secret word. If you don't,
      keep bluffing!
    </>
  ),
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10 mins',
    },
    {
      emoji: '👥',
      text: '3+ players',
    },
  ],
  minPlayers: 3,
  howToSlides: [
    {
      text: 'One word is the secret topic',
      img: Slide1,
      altImg: Slide1Alt,
    },
    {
      text: 'Everyone but the spy knows the secret topic',
      img: Slide2,
    },
    {
      text: 'Give a one-word clue to convince everyone you know the topic',
      img: Slide3,
    },
    {
      text: 'Vote for who you think is the spy',
      img: Slide4,
    },
    {
      text: 'If caught, the spy can still win by guessing the topic',
      img: Slide5,
    },
  ],
} satisfies GameMetaData;
