import { ChakraTheme, extendTheme, theme } from '@chakra-ui/react';
import TokenTheme from '@components/Token/TokenTheme';
import colors from './colors';
import AlertTheme from './components/AlertTheme';
import ButtonTheme from './components/ButtonTheme';
import CardTheme from './components/CardTheme';
import DrawerTheme from './components/DrawerTheme';
import HeadingTheme from './components/HeadingTheme';
import InputTheme from './components/InputTheme';
import ModalTheme from './components/ModalTheme';
import TabsTheme from './components/TabsTheme';
import TextTheme from './components/TextTheme';
import TextareaTheme from './components/TextareaTheme';

export const LoadingScreenZIndex = 8000;
export const RuntimeErrorZIndex = 10_000;

// Chakra's extendTheme does an object merge, but we want to fully replace
// the breakpoints, so we set it to undefined here.
// See: https://github.com/chakra-ui/chakra-ui/issues/4813#issuecomment-939194128
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
theme.breakpoints = undefined;

// Same as above
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
theme.colors = undefined;

// Same as above
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
theme.radii = undefined;

// Same as above
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
theme.shadows = undefined;

// This is the default theme for the website.
// Room is the top scope.
// NOTE: if you modify this, you should run `npm run gen-theme-types` to update
// the types, which gives us stuff like autocomplete for theme colors (e.g. "MagicWhite")
const RoomTheme = extendTheme({
  colors,
  styles: {
    global: {
      body: {
        bg: 'MagicBlack',
        // white, not blue highlight
        WebkitTapHighlightColor: 'rgba(255, 255, 255, .4)',
        userSelect: 'none',
      },
    },
  },
  // https://chakra-ui.com/docs/styled-system/theme#z-index-values
  zIndices: {
    BackgroundImage: -1,
    DiscordServerCoverSheetButton: 1000,
    PublicRoomWidget: 1500,
    Slapper: 2000,
    EmoteWindow: 5000,
    PresentableOverlay: 5100,
    PresentableModal: 5410,
    // Note: chakra's toast z-index is 5500
    BreadToasterWindow: 6000,
    HowToPlayPopup: 6000,
    PublicRoomDrawer: 6900,
    SystemDrawer: 7000,
    PollModal: 7001,
    PurchaseModal: 7002,
    ReportPlayerModal: 7003,
    DialogOverlay: 7010,
    DialogModal: 7010,
    GameStartingCountdown: 7700,
    ConnectionIssuesModal: 8000,
    LoadingScreenZIndex,
    CriticalErrorModalOverlay: 8010,
    CriticalErrorModal: 8020,
    RuntimeErrorZIndex,
  },
  fonts: {
    body: 'GreyCliff CF',
    heading: 'GreyCliff CF',
    textSlap: 'Shrikhand',
  },
  // https://chakra-ui.com/docs/styled-system/responsive-styles#customizing-breakpoints
  breakpoints: {
    smallMobile: '0px',
    mediumMobile: '380px',
    largeMobile: '768px',
  },
  fontWeights: {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    demibold: 600,
    bold: 700,
    extrabold: 800,
    heavy: 900,
  },
  fontSizes: {
    xs: '14px',
    sm: '16px',
    md: '20px',
    lg: '24px',
    xl: '32px',
    '2xl': '36px',
    '4xl': '48px',
    'textSlapper-default': '60px',
    'textSlapper-mini': '38px',
  },
  shadows: {
    cartoon: '0px 4px 0px 0px rgba(0, 0, 0, 0.25)',
  },
  radii: {
    button: '50px',
    full: '9999px', // need this one for IconButton.isRound = true
    card: '12px',
    input: '6px',
  },
  components: {
    // Built-in components
    Button: ButtonTheme,
    Tabs: TabsTheme,
    Text: TextTheme,
    Input: InputTheme,
    Textarea: TextareaTheme,
    Heading: HeadingTheme,
    Modal: ModalTheme,
    Alert: AlertTheme,
    Card: CardTheme,
    Drawer: DrawerTheme,
    // Custom components
    Token: TokenTheme,
  },
} satisfies Partial<ChakraTheme>);

export default RoomTheme;
